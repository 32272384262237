const config = {
  supabase_url: process.env.NEXT_PUBLIC_SUPABASE_URL!,
  supabase_anon_key: process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY!,
  google_client_id: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID!,
  site_url: process.env.NEXT_PUBLIC_SITE_URL!,
  vercel_url: process.env.NEXT_PUBLIC_VERCEL_URL!,
  openai_api_key: process.env.OPENAI_API_KEY!,
  onesignal_app_id: process.env.NEXT_PUBLIC_ONESIGNAL_APP_ID!,
  google_tag_manager_id: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID,
  vercel_env: process.env.NEXT_PUBLIC_VERCEL_ENV,
  supabase_service_role_key: process.env.SUPABASE_SERVICE_ROLE_KEY,
  package_version: process.env.version,
  mailerlite_api_key: process.env.MAILERLITE_API_KEY,
  mailerlite_balance_group_id: process.env.MAILERLITE_BALANCE_GROUP_ID,
}

export default config
